import React from 'react';
import config from '../config/config';
import ContractDebit from '../pages/applications/Contracts/ContractDebit';
import ContractCredit from '../pages/applications/Contracts/ContractCredit';
import { Redirect } from 'react-router-dom';
import { LocalPermissions } from '../helpers/constants';
import ContractCreditDefault from '../pages/applications/Contracts/ConnectContractDefault';

const Routes = [
  // Offers
  {
    path: '/app/offers/popular',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_OFFERS_POPULAR_OFFERS,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/offers/PopularOffersPage/PopularOffersPage`
      ),
    ),
  },
  {
    path: '/app/offers/manage-customized-category/:id?',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_OFFERS_CUSTOM_CATEGORY_OFFERS,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/offers/CustomizedCategoryPage/ManageCustomizedCategoryPage`
      ),
    ),
  },
  {
    path: '/app/offers/onboarding',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_OFFERS_ONBOARDING_OFFERS,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/offers/OnboardingOffersPage/OnboardingOffersPage`
      ),
    ),
  },
  {
    path: '/app/offers/banners/create',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_BANNERS_CREATE,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/offers/CreateBannerPage/CreateBannerPage`
      ),
    ),
  },
  {
    path: '/app/offers/banners',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_BANNERS,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/offers/BannersPage/BannersPage`),
    ),
  },
  {
    path: '/app/offers/create',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_OFFERS_OFFERS_CREATE,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/offers/OffersPage/ManageOfferPage`),
    ),
  },
  {
    path: '/app/offers/bulk',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_OFFERS_OFFERS_BULK,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/offers/OffersPage/OffersInBulkPage`),
    ),
  },
  {
    path: '/app/offers/cards/bulk',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_OFFERS_OFFERS_BULK,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/offers/OffersPage/CardOffersInBulk`),
    ),
  },
  {
    path: '/app/offers/manage/:id?',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_OFFERS_OFFERS_MANAGE_ID,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/offers/OffersPage/ManageOfferPage`),
    ),
  },
  {
    path: '/app/offers/cards/manage/:id?',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_CMT,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/offers/OffersPage/CardOffers/`),
    ),
  },
  {
    path: '/app/offers',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_OFFERS,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/offers/OffersPage/OffersPage`),
    ),
  },

  // Merchants
  {
    path: '/app/merchants/list',
    enabled: config.ENABLED_ROUTES.MERCHANTS,
    permission: LocalPermissions.MANAGE_MERCHANT,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/merchants/Merchants/list/List`),
    ),
  },
  {
    path: '/app/merchants/manage/:id?',
    enabled: config.ENABLED_ROUTES.MERCHANTS,
    permission: LocalPermissions.MANAGE_MERCHANT,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/merchants/Merchants/manage/ManageMerchant`
      ),
    ),
  },
  {
    path: '/app/merchants/merchant-bulk',
    enabled: config.ENABLED_ROUTES.MERCHANTS,
    permission: LocalPermissions.MANAGE_MERCHANT_BULK,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/merchants/Merchants/bulk-upload/BulkUpload`
      ),
    ),
  },
  {
    path: '/app/merchants/cati-bulk',
    enabled: config.ENABLED_ROUTES.MERCHANTS,
    permission: LocalPermissions.MANAGE_MERCHANT_CATI_BULK,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/merchants/CATIBulkUpload/CATIBulkUpload`),
    ),
  },

  // Branches
  {
    path: '/app/branches/bulk',
    enabled: config.ENABLED_ROUTES.OFFERS,
    permission: LocalPermissions.MANAGE_OFFERS_BRANCHES_BULK,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/offers/BranchesPage/BranchesInBulkPage`),
    ),
  },

  // Aplications
  {
    path: '/app/applications/profile-validation/:id',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission:
      LocalPermissions.MANAGE_FINANCING_APPLICATIONS_PROFILE_VALIDATION_ID,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/applications/ProfileValidationEdit/ProfileValidationEdit`
      ),
    ),
  },
  {
    path: '/app/applications/nid-download',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission: LocalPermissions.MANAGE_FINANCING_APPLICATIONS,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/applications/NidDownload/NidDownload`),
    ),
  },
  {
    path: '/app/applications/profile-validation',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission:
      LocalPermissions.MANAGE_FINANCING_APPLICATIONS_PROFILE_VALIDATION,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/applications/ProfileValidation/ProfileValidation`
      ),
    ),
  },
  {
    path: '/app/applications/contracts/lucky',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission: LocalPermissions.MANAGE_FINANCING_APPLACATIONS_PREFILL_CONTRACT,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/applications/Contracts/Contracts`),
    ),
  },
  {
    path: '/app/applications/contracts/connect',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission:
      LocalPermissions.MANAGE_FINANCING_APPLACATIONS_PREFILL_CONNECT_CONTRACT,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/applications/Contracts/ConnectContracts`),
    ),
  },
  {
    path: '/app/applications/blacklist',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission: LocalPermissions.MANAGE_OFFERS_BRANCHES_BULK,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/applications/BlackList/BlackList`),
    ),
  },

  // Affiliates
  {
    path: '/app/affiliate/merchants/manage/:id?',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_AFFILIATE_MERCHANTS_ID,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/affiliate/Merchants/ManageMerchant`),
    ),
  },
  {
    path: '/app/affiliate/merchants/popular',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_AFFILIATE_MERCHANTS_POPULAR,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/affiliate/PopularAffiliateMerchants/PopularAffiliateMerchants`
      ),
    ),
  },
  {
    path: '/app/affiliate/merchants',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_AFFILIATE_MERCHANTS,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/affiliate/Merchants/Merchants`),
    ),
  },
  {
    path: '/app/affiliate/categories',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_AFFILIATE_CATEGORIES,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/affiliate/Categories/Categories`),
    ),
  },
  {
    path: '/app/affiliate/transactions/upload',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_AFFILIATE_TRANSACTIONS,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/affiliate/AffiliateTransactionsPage/AffiliateTransactionsPage`
      ),
    ),
  },
  {
    path: '/app/affiliate/contest',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_AFFILIATE_CONTEST,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/affiliate/AffiliateContestPage/AffiliateContestPage`
      ),
    ),
  },
  {
    path: '/app/affiliate/banners',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_AFFILIATE_BANNERS,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/affiliate/Banners/AffiliateBannersPage`),
    ),
  },
  {
    path: '/app/affiliate/cashout/:id',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_AFFILIATE_CASHOUT_ID,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/affiliate/CashoutRequests/CashoutRequest`
      ),
    ),
  },
  {
    path: '/app/affiliate/cashout',
    enabled: config.ENABLED_ROUTES.AFFILIATE,
    permission: LocalPermissions.MANAGE_AFFILIATE_CASHOUT,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/affiliate/CashoutRequests/CashoutRequests`
      ),
    ),
  },

  // Financing
  {
    path: '/app/financing/assignPartner',
    enabled: config.ENABLED_ROUTES.FINANCING,
    permission: LocalPermissions.MANAGE_FINANCING_APPLICATIONS_ASSIGNED_PARTNER,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/financing/AssignPartnerPage/AssignPartnerPage`
      ),
    ),
  },
  {
    path: '/app/financing/unblockUsers',
    enabled: config.ENABLED_ROUTES.FINANCING,
    permission: LocalPermissions.MANAGE_FINANCING_APPLICATIONS_UNBLOCK_USER,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/financing/UnblockUsersPage/UnblockUsersPage`
      ),
    ),
  },
  {
    path: '/app/financing/repayments',
    enabled: config.ENABLED_ROUTES.FINANCING,
    permission: LocalPermissions.MANAGE_FINANCING_REPAYMENTS_UPLOAD,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/financing/UploadRepayments`),
    ),
  },
  {
    path: '/app/financing/applications/:documentNumber',
    enabled: config.ENABLED_ROUTES.FINANCING,
    permission: LocalPermissions.MANAGE_FINANCING_APPLICATION_REVIEWS,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/financing/FinancingApplicationsEdit/FinancingApplicationsEdit`
      ),
    ),
  },
  {
    path: '/app/financing/applications',
    enabled: config.ENABLED_ROUTES.FINANCING,
    permission: LocalPermissions.MANAGE_FINANCING_APPLICATION_REVIEWS,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/financing/FinancingApplicationsPage/FinancingApplicationsPage`
      ),
    ),
  },
  {
    path: '/app/financing/application-reviews',
    enabled: config.ENABLED_ROUTES.FINANCING,
    permission: LocalPermissions.MANAGE_FINANCING_APPLICATION_REVIEWS,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/financing/UploadApplicationReviews`),
    ),
  },
  {
    path: '/app/financing/credit-promotions/upload-user',
    enabled: config.ENABLED_ROUTES.FINANCING,
    permission: LocalPermissions.MANAGE_FINANCING_CREDIT_PROMOTION,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/financing/UserCreditPromotions`),
    ),
  },
  {
    path: '/app/financing/credit-promotions',
    enabled: config.ENABLED_ROUTES.FINANCING,
    permission: LocalPermissions.MANAGE_FINANCING_CREDIT_PROMOTION,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/financing/CreditPromotion`),
    ),
  },

  // General
  {
    path: '/app/general/files',
    enabled: config.ENABLED_ROUTES.GENERAL,
    permission: LocalPermissions.MANAGE_GENERAL_FILES,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/general/FilesPage/FilesPage`),
    ),
  },
  {
    path: '/app/general/sms',
    enabled: config.ENABLED_ROUTES.GENERAL,
    permission: LocalPermissions.MANAGE_CMT_GENERAL,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/general/SmsPage/SmsPage`),
    ),
  },
  {
    path: '/app/general/plus/manage/:id?',
    enabled: config.ENABLED_ROUTES.GENERAL,
    permission: LocalPermissions.MANAGE_GENERAL_PLUS,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/general/PlusPage/CreatePlusPage`),
    ),
  },
  {
    path: '/app/general/plus',
    enabled: config.ENABLED_ROUTES.GENERAL,
    permission: LocalPermissions.MANAGE_GENERAL_PLUS,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/general/PlusPage/PlusPage`),
    ),
  },
  {
    path: '/app/general/users/manage',
    enabled: config.ENABLED_ROUTES.GENERAL,
    permission: LocalPermissions.MANAGE_GENERAL_APP_USERS,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/general/Users/UserManagementPage`),
    ),
  },
  {
    path: '/app/general/local-users/manage',
    enabled: config.ENABLED_ROUTES.GENERAL,
    permission: LocalPermissions.MANAGE_CMT,
    LazyComponent: React.lazy(() =>
      import(`${__dirname}../../pages/general/LocalUsers/LocalUsersPage`),
    ),
  },

  // Feature Flags
  {
    path: '/app/feature-flags/manage/:id?',
    enabled: config.ENABLED_ROUTES.FEATURE_FLAGS,
    permission: LocalPermissions.MANAGE_GENERAL_FEATURE_FLAGS,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/feature-flags/FeatureFlagPage/CreateEditFeatureFlagPage`
      ),
    ),
  },
  {
    path: '/app/feature-flags',
    enabled: config.ENABLED_ROUTES.FEATURE_FLAGS,
    permission: LocalPermissions.MANAGE_GENERAL_FEATURE_FLAGS,
    LazyComponent: React.lazy(() =>
      import(
        `${__dirname}../../pages/feature-flags/FeatureFlagPage/FeatureFlagPage`
      ),
    ),
  },

  // Applications (Without Layout)
  {
    path: '/without-layout/applications/contracts-debit',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission: LocalPermissions.MANAGE_FINANCING_APPLACATIONS_PREFILL_CONTRACT,
    Component: ContractDebit,
  },
  {
    path: '/without-layout/applications/contracts-credit',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission: LocalPermissions.MANAGE_FINANCING_APPLACATIONS_PREFILL_CONTRACT,
    Component: ContractCredit,
  },
  {
    path: '/without-layout/applications/contracts/connect/default',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission:
      LocalPermissions.MANAGE_FINANCING_APPLACATIONS_PREFILL_CONNECT_CONTRACT,
    Component: ContractCreditDefault,
  },
  {
    path: '/without-layout',
    enabled: config.ENABLED_ROUTES.APPLICATIONS,
    permission: LocalPermissions.MANAGE_FINANCING_APPLACATIONS_PREFILL_CONTRACT,
    Component: () => <Redirect to="/app/applications/contracts/lucky" />,
  },
];

const EnabledRoutes = Routes.filter(route => route.enabled);

const DefaultLayoutEnabledRoutes = EnabledRoutes.filter(route =>
  route.path.startsWith('/app'),
);

const WithoutLayoutEnabledRoutes = EnabledRoutes.filter(route =>
  route.path.startsWith('/without-layout'),
);

export {
  Routes,
  EnabledRoutes,
  DefaultLayoutEnabledRoutes,
  WithoutLayoutEnabledRoutes,
};
