import axios from './axios.service';

export default class Financing {
  /**
   *
   * @param filters
   */
  static listApplications(filters: any) {
    return axios.get(`/api/v2/cmt-backend/financing/applications`, {
      params: filters,
    });
  }

  /**
   *
   * @param filters
   */
  static exportApplications(filters: any) {
    return axios.get(`/api/v2/cmt-backend/financing/export-applications`, {
      params: filters,
    });
  }

  /**
   *
   * @param partnerId: partnerId to assign
   * @param usersIds: list of users ids to modify
   * @param activationType: activation method
   */
  static assignPartnerToUsers(
    partnerId: any,
    usersIds: any,
    activationType: any,
    financingBalanceAmount: any,
  ) {
    return axios.post(
      '/api/v2/cmt-backend/financing/partner',
      {
        partnerId: partnerId,
        usersIds: usersIds,
        activationType: activationType,
        financingBalanceAmount: financingBalanceAmount,
      },
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
  }

  static unblockUsers(usersIds: string[], unblockReason: number) {
    return axios.post(
      '/api/v2/cmt-backend/financing/unblock-users',
      {
        usersIds: usersIds,
      },
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
  }

  /**
   *
   * @param filters
   */
  static listPartners(filters?: any) {
    return axios.get(`/api/v2/cmt-backend/financing/partners`, {
      params: filters,
    });
  }

  static getActivationProcessMethods() {
    return axios.get(`/api/v2/cmt-backend/financing/partners`);
  }

  static getCreditLimit(phoneNumber: any, nationalIDNumber: any) {
    return axios.post(
      `/api/v2/cmt-backend/financing/users/${phoneNumber}/credit`,
      {
        IDCardNumber: nationalIDNumber,
      },
    );
  }

  static registerVerificationAccount(luckyUserId: string, reviewedBy: string) {
    return axios.post(`/api/v2/cmt-backend/financing/users/verify-signature`, {
      luckyUserId,
      reviewedBy,
    });
  }

  static repaymentsConfirmProcessing(uuid: string) {
    return axios.post(
      `/api/v2/cmt-backend/financing/repayments/process-batch/${uuid}`,
      {},
    );
  }

  static processApplication(
    luckyUserId: string,
    status: string,
    statusReason: string,
    creditLimit: number,
  ) {
    return axios.put(`/api/v2/cmt-backend/financing/application-review`, {
      luckyUserId,
      action: status,
      reason: statusReason,
      creditLimit,
    });
  }

  static applicationReviewsConfirmProcessing(uuid: string) {
    return axios.post(
      `/api/v2/cmt-backend/financing/application-reviews/process-batch/${uuid}`,
      {},
    );
  }

  static createCreditPromotion(body: {
    product: string;
    interestPercentage: number;
    minAmountValue: number;
    fromDate: Date;
    toDate: Date;
  }) {
    return axios.post(`/api/v2/cmt-backend/financing/credit-promotions`, body);
  }

  static listCreditPromotions(params: { page: number; status: string }) {
    return axios.get(
      `/api/v2/cmt-backend/financing/credit-promotions?page=${params.page}&status=${params.status}`,
    );
  }

  static endCreditPromotion(body: { promotionId: number }) {
    return axios.put(
      `/api/v2/cmt-backend/financing/credit-promotions/expire`,
      body,
    );
  }
}
