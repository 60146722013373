import config from '../../config/config';

const MenuItems = [
  ...(config.ENABLED_ROUTES.OFFERS
    ? [
        {
          title: 'Offers',
          children: [
            {
              title: 'Popular on lucky',
              path: '/app/offers/popular',
            },
            {
              title: 'Customized category',
              path: '/app/offers/manage-customized-category',
            },
            {
              title: 'Banners',
              path: '/app/offers/banners',
            },
            {
              title: 'Onboarding Offers',
              path: '/app/offers/onboarding',
            },
            {
              title: 'Offers List',
              path: '/app/offers',
            },
            {
              title: 'Branches',
              path: '/app/branches/bulk',
            },
          ],
        },
      ]
    : []),
  ...(config.ENABLED_ROUTES.MERCHANTS
    ? [
        {
          title: 'Merchants',
          children: [
            {
              title: 'Merchants list',
              path: '/app/merchants/list',
            },
            {
              title: 'CATI bulk upload',
              path: '/app/merchants/cati-bulk',
            },
          ],
        },
      ]
    : []),
  ...(config.ENABLED_ROUTES.APPLICATIONS
    ? [
        {
          title: 'Applications',
          children: [
            {
              title: 'Profile Validation',
              path: '/app/applications/profile-validation',
            },
            {
              title: 'Lucky contracts',
              path: '/app/applications/contracts/lucky',
            },
            {
              title: 'Connect contracts',
              path: '/app/applications/contracts/connect',
            },
            {
              title: 'NID Download',
              path: '/app/applications/nid-download',
            },
            {
              title: 'Blacklist',
              path: '/app/applications/blacklist',
            },
          ],
        },
      ]
    : []),
  ...(config.ENABLED_ROUTES.AFFILIATE
    ? [
        {
          title: 'Affiliate',
          children: [
            {
              title: 'Merchants',
              path: '/app/affiliate/merchants',
            },
            {
              title: 'Categories',
              path: '/app/affiliate/categories',
            },
            {
              title: 'Popular Merchants',
              path: '/app/affiliate/merchants/popular',
            },
            {
              title: 'Upload Transactions',
              path: '/app/affiliate/transactions/upload',
            },
            ...(config.ENABLED_ROUTES.CASHOUTS
              ? [
                  {
                    title: 'Cashout Requests',
                    path: '/app/affiliate/cashout',
                  },
                ]
              : []),
            {
              title: 'Manage Affiliate Contest',
              path: '/app/affiliate/contest',
            },
            {
              title: 'Manage Affiliate Banners',
              path: '/app/affiliate/banners',
            },
          ],
        },
      ]
    : []),
  ...(config.ENABLED_ROUTES.FINANCING
    ? [
        {
          title: 'Financing',
          children: [
            {
              title: 'Applications',
              path: '/app/financing/applications',
            },
            {
              title: 'Repayments',
              path: '/app/financing/repayments',
            },
            {
              title: 'Unblock users',
              path: '/app/financing/unblockUsers',
            },
            {
              title: 'Credit promotion',
              path: '/app/financing/credit-promotions',
            },
          ],
        },
      ]
    : []),
  ...(config.ENABLED_ROUTES.GENERAL
    ? [
        {
          title: 'General',
          children: [
            {
              title: 'Files',
              path: '/app/general/files',
            },
            {
              title: 'SMS',
              path: '/app/general/sms',
            },
            {
              title: 'Plus',
              path: '/app/general/plus',
            },
            {
              title: 'App User management',
              path: '/app/general/users/manage',
            },
            {
              title: 'Local Users management',
              path: '/app/general/local-users/manage',
            },
          ],
        },
      ]
    : []),
  ...(config.ENABLED_ROUTES.FEATURE_FLAGS
    ? [
        {
          title: 'Feature flags',
          children: [
            {
              title: 'List',
              path: '/app/feature-flags',
            },
          ],
        },
      ]
    : []),
];

export { MenuItems };
